import { Component } from '@angular/core';
import { SpinnerService } from './core/services/spinner.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dorsin';
  isLoading = this.spinner.isLoading;

  constructor(private spinner: SpinnerService) {}
}
