import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerService } from './services/spinner.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { SpinnerTHComponent } from './components/spinner-th/spinner-th.component';

@NgModule({
  declarations: [SpinnerTHComponent],
  imports: [CommonModule],
  exports: [SpinnerTHComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
