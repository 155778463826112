import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isLoading = new Subject<boolean>();

  showSpinner() {
    setTimeout(() => {
      this.isLoading.next(true);
    }, 0);
  }

  hideSpinner() {
    setTimeout(() => {
      this.isLoading.next(false);
    }, 0);
  }

}
